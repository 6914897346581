<template>
  <b-card>
    <div class="flex items-center p-1">
      <b-avatar
        size="6rem"
        :src="dataPerformance.photo_profile_url"
      />
      <div class="ml-1 text-black">
        <strong
          class="font-black text-2xl"
        >Hai, {{ dataPerformance.full_name }} !</strong>
        <div>ini adalah update performa kamu</div>
      </div>
    </div>
    <b-row class="justify-content-end">
      <b-dropdown
        variant="primary"
        class="mr-1 p-1"
        no-caret
      >
        <template
          #button-content
        >
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-add.svg"
          />
        </template>
        <b-dropdown-item
          class=" text-black font-bold text-[12px]"
          @click="filter('filter')"
        >
          Filter
        </b-dropdown-item>
        <b-dropdown-item
          class="font-medium text-[12px]"
          :active="isActiveMonth"
          @click="filter('monthThis')"
        >
          Bulan Ini
        </b-dropdown-item>
        <b-dropdown-item
          class=" text-black font-medium text-[12px]"
          :active="isActiveLastMonth"
          @click="filter('lastMonth')"
        >
          Bulan Lalu
        </b-dropdown-item>
        <b-dropdown-item
          class=" text-black font-medium text-[12px]"
          :active="isActiveEveryTime"
          @click="filter('everyTime')"
        >
          Sepanjang Waktu
        </b-dropdown-item>
      </b-dropdown>
    </b-row>
    <b-row class="p-1">
      <b-col lg="4">
        <div class="card-performance">
          <b-card>
            <p
              class="font-black text-black text-base"
              style="margin-bottom: 10px"
            >
              Kecepatan Respons
            </p>
            <div
              class="flex justify-between"
              style="margin-bottom: 5px"
            >
              <div class="text-black">
                {{ handleAverageResponseTime(dataPerformance.response_speed) }}
              </div>
              <b-skeleton
                width="40%"
                :style="handleBarAverageTime(dataPerformance.response_speed)"
              />
            </div>
            <div
              class="text-[12px]"
              style="margin-bottom: 10px"
            >
              *Peringkat {{ responseSpeed.ranking }} dari {{ responseSpeed.total_cs_expedition }} CS Ekspedisi
            </div>
            <p
              class="font-black text-black text-base"
              style="margin-bottom: 10px"
            >
              Kecepatan Penanganan
            </p>
            <div class="flex justify-between">
              <div class="text-black">
                {{ handleTime(dataPerformance.handling_speed) }}
              </div>
              <b-skeleton
                width="40%"
                :style="handleBarTime(dataPerformance.handling_speed)"
              />
            </div>
            <div class="text-[12px]">
              *Peringkat {{ handlingSpeed.ranking }} dari {{ handlingSpeed.total_cs_expedition }} CS Ekspedisi
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="card-performance">
          <b-card
            :class="classHandlingPercentage(dataPerformance.handling_success)"
          >
            <p class="font-black text-white text-base">
              Kesuksesan Penanganan
            </p>
            <div
              class="flex items-center"
              style="margin-bottom: 12px; margin-top: 1em"
            >
              <vc-donut
                :class="handleBackgroundChart(dataPerformance.handling_success)"
                :sections="[{ value: dataPerformance.handling_success }]"
                :size="sizeDounut"
              />
              <div class="ml-1">
                <p class="text-white text-3xl font-bold">
                  {{ dataPerformance.handling_success }}% sukses
                </p>
                <div class="text-white">
                  dari {{ dataPerformance.total_order }} pengiriman
                </div>
              </div>
            </div>
            <div
              class="text-[12px] text-white"
              style="margin-bottom: 15px; margin-top: 20px"
            >
              *Peringkat {{ handlingSuccess.ranking }} dari {{ handlingSuccess.total_cs_expedition }} CS Ekspedisi
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="card-performance">
          <b-card :class="classHandlingReview(dataPerformance.rating)">
            <p class="font-black text-white text-base">
              Review untuk kamu
            </p>
            <div
              class="flex items-center"
              style="margin-top: 1em"
            >
              <img
                src="@/assets/images/svg/smile.svg"
                class="mr-1"
              >
              <span
                class="text-white"
              >{{ dataPerformance.total_review }} review</span>
            </div>
            <div class="flex mt-2">
              <div class="point">
                {{ dataPerformance.rating }}
              </div>
              <b-form-rating
                v-model="dataPerformance.rating"
                readonly
                class="rating"
                color="#FFFFFF"
              />
            </div>
            <div
              class="text-[12px] text-white"
              style="margin-bottom: 15px; margin-top: 15px"
            >
              *Peringkat {{ rating.ranking }} dari {{ rating.total_cs_expedition }} CS Ekspedisi
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <div class="mx-1 mt-3">
      <h5 class="text-black">
        <strong>
          SLA Monitoring
        </strong>
      </h5>

      <b-row class="mt-2">
        <b-col md="5" lg="4" class="mb-2">
          <div class="wrapper-delivery-attempt p-2">
            <div class="mt-1">
              <h5 class="text-white">
                <strong>
                  Percobaan Antar
                </strong>
              </h5>
            </div>
            <div class="d-flex align-items-center">
              <h1 class="text-white mr-1" style="font-size: 72px;">
                <strong>
                  {{ String(deliveryAttempt).includes('.') ? Number(deliveryAttempt).toFixed(1) : deliveryAttempt }}
                </strong>
              </h1>
              <h2 class="text-white" style="font-size: 32px;">
                <strong>
                  Kali
                </strong>
              </h2>
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <div>
                <span class="text-white">
                  <strong>
                    {{ String(deliveryFirstTimes).includes('.') ? Number(deliveryFirstTimes).toFixed(1) : deliveryFirstTimes }}
                  </strong>
                </span>
                <small class="text-white">
                   resi diantar 1 kali
                </small>
              </div>
              <div class="cursor-pointer" @click="exportData('delivery_attempt_1')">
                <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-download.svg" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="5" lg="4" class="mb-2">
          <div class="wrapper-long-hold p-2">
            <div class="mt-1">
              <h5 class="text-white">
                <strong>
                  Durasi Hold
                </strong>
              </h5>
            </div>
            <div class="d-flex align-items-center">
              <h1 class="text-white mr-1" style="font-size: 72px;">
                <strong>
                  {{ String(holdDuration).includes('.') ? Number(holdDuration).toFixed(1) : holdDuration }}
                </strong>
              </h1>
              <h2 class="text-white" style="font-size: 32px;">
                <strong>
                  Hari
                </strong>
              </h2>
            </div>
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <div>
                <span class="text-white">
                  <strong>
                    {{ String(less4holdDay).includes('.') ? Number(less4holdDay).toFixed(1) : less4holdDay }}
                  </strong>
                </span>
                <small class="text-white">
                   resi di-hold &lt; 4 hari
                </small>
              </div>
              <div class="cursor-pointer" @click="exportData('hold_less_4')">
                <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-download.svg" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import {
  firstDateOfMonth, komshipDate, today, lastDateOfMonth,
} from '@/store/helpers'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      today,
      komshipDate,
      firstDateOfMonth,
      lastDateOfMonth,
      dataPerformance: {},
      handlingSpeed: {},
      responseSpeed: {},
      handlingSuccess: {},
      rating: {},
      sizeDounut: 80,
      isActiveMonth: true,
      isActiveLastMonth: false,
      isActiveEveryTime: false,
      startDate: moment(firstDateOfMonth).format('YYYY-MM-DD'),
      endDate: moment(today).format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState('dashboard', [
      'deliveryAttempt',
      'deliveryFirstTimes',
      'lessHoldDay',
      'holdDuration',
      'totalDeliveryAttempt',
      'totalHoldDuration',
      'less4holdDay',
    ]),
  },
  async mounted() {
    await this.fetchSlaMonitoring()
    this.getDataPerformance()
  },
  methods: {
    getDataPerformance() {
      const userId = this.$store.state.auth.userData.id
      this.$http_komship
        .get(`/v1/ticket-admin/performance-mitra-by-id/${userId}`, { timeout: 60000 }, {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
          },
        })
        .then(res => {
          const { data } = res.data
          this.dataPerformance = data
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        })
      this.$http_komship
        .get('/v1/ticket-mitra/ranking/performance/response-handling-speed', { timeout: 60000 }, {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
          },
        })
        .then(res => {
          this.handlingSpeed = res.data.data.ranking_handling_speed
          this.responseSpeed = res.data.data.ranking_response_speed
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        })
      this.$http_komship
        .get('/v1/ticket-mitra/ranking/performance/rating-handling-success', { timeout: 60000 }, {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
          },
        })
        .then(res => {
          this.handlingSuccess = res.data.data.ranking_handling_success
          this.rating = res.data.data.ranking_rating
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    handleAverageResponseTime(value) {
      if (value?.hours === 0) {
        return `${value?.minutes} menit`
      }
      return `${value?.hours} jam ${value?.minutes} menit`
    },
    handleTime(value) {
      if (value?.hours === 0) {
        return `${value?.minutes} menit`
      }
      return `${value?.hours} jam ${value?.minutes} menit`
    },
    handleBarAverageTime(value) {
      if (value?.hours < 2) {
        return 'background-color: #B6FBC9;'
      }
      if (value?.hours > 2 && value?.hours < 6) {
        return 'background-color: #FFDF82;'
      }
      if (value?.hours > 6) {
        return 'background-color: #E31A1A;'
      }
      return 'background-color: #B6FBC9;'
    },
    handleBarTime(value) {
      if (value?.hours < 2) {
        return 'background-color: #B6FBC9;'
      }
      if (value?.hours > 2 && value?.hours < 6) {
        return 'background-color: #FFDF82;'
      }
      if (value?.hours > 6) {
        return 'background-color: #E31A1A;'
      }
      return 'background-color: #B6FBC9;'
    },
    handleBackgroundChart(value) {
      if (value > 60) {
        return 'donut-good'
      }
      if (value < 60) {
        return 'donut-bad'
      }
      return 'donut-good'
    },
    classHandlingPercentage(value) {
      if (value > 60) {
        return 'card-performance-success'
      }
      if (value < 60) {
        return 'card-performance-bad'
      }
      return 'card-performance-success'
    },
    classHandlingReview(value) {
      if (value > 3) {
        return 'card-review-good'
      }
      if (value < 60) {
        return 'card-review-bad'
      }
      return 'card-review-good'
    },
    filter(time) {
      if (time === 'monthThis' || time === 'filter') {
        this.startDate = moment(this.firstDateOfMonth).format('YYYY-MM-DD')
        this.endDate = moment(this.today).format('YYYY-MM-DD')
        this.isActiveMonth = true
        this.isActiveLastMonth = false
        this.isActiveEveryTime = false
      }
      if (time === 'lastMonth') {
        this.startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        this.endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        this.isActiveMonth = false
        this.isActiveLastMonth = true
        this.isActiveEveryTime = false
      }
      if (time === 'everyTime') {
        this.startDate = moment(this.komshipDate).format('YYYY-MM-DD')
        this.endDate = moment(this.today).format('YYYY-MM-DD')
        this.isActiveMonth = false
        this.isActiveLastMonth = false
        this.isActiveEveryTime = true
      }
      this.getDataPerformance()
      this.fetchSlaMonitoring()
    },
    fetchSlaMonitoring() {
      const paramsSla = {
        id: this.$store.state.auth.userData.id,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      this.$store.dispatch('dashboard/FetchSlaMonitoring', paramsSla).then(
        res => {},
        err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        },
      )
    },
    exportData(state) {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        shipping: this.$store.state.auth.userData.mitras.owner === 'J&T' ? 'J%26T' : this.$store.state.auth.userData.mitras.owner,
        withHoldDuration: state,
      }
      this.$store.dispatch('dashboard/ExportData', params).then(
        res => {
          const { data } = res.data
          const base = data.base64
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base}`
          const downloadLink = document.createElement('a')
          downloadLink.download = data.filename
          downloadLink.href = linkSource
          downloadLink.target = '_blank'
          downloadLink.click()
        },
        err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            },
            2000,
          )
        },
      )
    },
  },
}
</script>
<style lang="scss" scoped>
@import './Dashboard.scss'
</style>
